<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <BaseButton
          id="btn-create"
          name="btn-create"
          color="secondary"
          title="Novo cadastro"
          icon="fa-solid fa-plus"
          @click="create"
          v-permission="{ roles: ['A1', 'CC1'], operator: '===' }"
        />
      </template>
    </BaseTitlePage>

    <BaseSearchPage title="Consultar e filtrar por usuários existente">
      <template v-slot:form>
        <v-form @submit.prevent="search" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12" xl="4" lg="4" md="4" sm="10">
              <v-text-field
                id="filter"
                name="filter"
                label="Nome ou CPF"
                clearable
                dense
                outlined
                hide-details
                v-model="filter"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="2">
              <BaseButton
                id="btn-search"
                name="btn-search"
                type="submit"
                height="40"
                color="primary"
                icon="fa-solid fa-magnifying-glass"
                title="Filtrar"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de usuários">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.document`]="{ item }">
            {{ formatCpf(item.document) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="success"
                  class="mr-2"
                  v-on="on"
                  @click="edit(item)"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip left v-if="item.situation === 'Primeiro Acesso'">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="primary"
                  v-on="on"
                  @click="sendEmail(item)"
                  v-permission="{ roles: ['A1', 'CC1'], operator: '===' }"
                >
                  fa-regular fa-paper-plane
                </v-icon>
              </template>
              <span>Reenviar email de ativação</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <Edit
      v-if="dialog"
      :title="modalTitle"
      :dialog="dialog"
      :isEdit="editMode"
      :userId="selectedItem"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { showMessage } from '@/helpers/messages';
import { formatCpf } from '@/helpers/formatting';
import { unmask } from '@/helpers/utils';
import { UserService } from '@/services/api/user';

export default {
  components: {
    Edit: () => import('@/views/AccessControl/User/Edit')
  },

  data: () => ({
    filter: null,
    options: {},
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'Documento', value: 'document' },
      { text: 'Email', value: 'email' },
      { text: 'Situação', value: 'situation' },
      { text: '', value: 'action' }
    ],
    dialog: false,
    selectedItem: '',
    modalTitle: '',
    editMode: false,
    items: [],
    totalRecords: 0
  }),

  methods: {
    formatCpf,

    create() {
      this.selectedItem = '';
      this.editMode = false;
      this.modalTitle = 'Adicionar usuário';
      this.dialog = !this.dialog;
    },

    edit(item) {
      this.selectedItem = item.id;
      this.editMode = true;
      this.modalTitle = 'Visualizar usuário';
      this.dialog = !this.dialog;
    },

    closeModal(create) {
      this.dialog = false;

      if (create) this.search();
    },

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const userService = new UserService();
        const { status, data } = await userService.getUsers({
          page,
          take: itemsPerPage,
          filter: this.filter ? unmask(this.filter) : this.filter
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async sendEmail(item) {
      try {
        const userService = new UserService();
        const { status } = await userService.sendEmail(item.id);

        if (status === 204) {
          showMessage('success', 'Email de ativação reenviado com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
